.app__footer {
    position: relative;
    padding: 2rem 0;
    bottom: 0;
    width: 100%;
    z-index: 0;
    min-height: 200px;

    @media screen and (max-width: 500px) {
      height: auto;
      padding: 0;
    }

    &-logo {
        width: 100%;
        height: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: inherit;
            height: inherit;
            object-fit: contain;
        }

        @media screen and (max-width: 500px) {
          height: 45px;
        }
    }
}

.copyright {
    width: 100%;
    padding: 1rem 0 0;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    p {
      text-transform: uppercase;
      color: var(--black-color);
    }
  }