.app__resume {
    flex: 1;
    width: 100%;
    flex-direction: column;

    &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &-img {
        margin-top: 1rem;
        width: 100%;
        height: 400px;

        img {
            width: inherit;
            height: inherit;
            object-fit: contain;
        }
    }

    &-item {
        width: 270px;
        flex-direction: column;

        margin: 0 2rem 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;

        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        }

        @media screen and (min-width: 2000px) {
            width: 470px;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }

        @media screen and (max-width: 300px) {
            width: 100%;
            margin: 1rem;
        }
    }

    &-button {
        
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--secondary-color);
            text-decoration: none;

            svg {
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }
        }
    }
}